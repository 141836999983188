import { A } from '@solidjs/router';
import { mergeProps, splitProps, Show } from 'solid-js';
import type { Component, JSX } from 'solid-js';
import { RingLoader } from '~/components/common/loaders';
import { cn } from '~/utils/classnames';

export type ButtonProps = {
  type?: 'submit' | 'reset' | 'button';
  href?: string;
  form?: string;
  variant?: 'outlined' | 'solid' | 'white' | 'text';
  color?: 'primary' | 'link' | 'black' | 'red';
  rounded?: 'md' | 'lg' | 'full';
  loading?: boolean;
  class?: string;
  disabled?: boolean;
  children: JSX.Element;
  onClick?: (ev?: MouseEvent) => void;
};

export const Button: Component<ButtonProps> = (originProps) => {
  const props = mergeProps(
    {
      type: 'button',
      color: 'primary',
      variant: 'solid',
      rounded: 'lg',
    } as ButtonProps,
    originProps
  );
  const [, tagProps] = splitProps(props, ['type', 'href', 'form', 'variant', 'color', 'rounded', 'loading', 'class']);

  const btnClass = () =>
    cn(
      'flex h-fit px-5 py-2.5 text-base leading-5',
      { border: props.variant !== 'text' },
      { 'border-primary-color': props.color === 'primary' },
      { 'border-border-level01': props.color === 'black' },
      { 'text-blue border-link hover:bg-blue/10': props.color === 'link' },
      { 'border-error text-error hover:bg-error/10': props.color === 'red' },
      { 'text-[var(--primary-color)] hover:bg-[var(--hover-color)] hover:text-white': props.color === 'primary' },
      { 'hover:text-white': props.color === 'primary' && props.variant === 'outlined' },
      { 'text-text-level01 hover:bg-text-level01/10': props.color === 'black' },
      { 'text-white': props.variant === 'solid' },
      {
        'bg-primary-color hover:bg-hover-color hover:text-white': props.variant === 'solid' && props.color === 'primary',
      },
      { 'bg-white hover:bg-white/90 hover:text-primary-color': props.variant === 'white' },
      { 'rounded-md': props.rounded === 'md' },
      { 'rounded-lg': props.rounded === 'lg' },
      { 'rounded-full': props.rounded === 'full' },
      { 'opacity-50 cursor-not-allowed': props.disabled },
      { 'aria-busy:bg-hover-color': props.variant === 'outlined' },
      props.class
    );

  const children = () => [
    <Show when={!props.loading} fallback={<RingLoader size={14} class="self-center" />}>
      <span class={cn('flex items-center justify-center gap-1')}>{props.children}</span>
    </Show>,
  ];

  return (
    <Show
      when={!props.href}
      fallback={
        <A href={props.href || ''} class={btnClass()} role="button" {...tagProps}>
          {children()}
        </A>
      }>
      <button
        class={btnClass()}
        type={props.type}
        form={props.form}
        {...tagProps}
        aria-busy={props.loading}
        onClick={(...args) => {
          if (props.disabled || props.loading) return;
          props.onClick?.(...args);
        }}>
        {children()}
      </button>
    </Show>
  );
};
