import { Component, mergeProps } from 'solid-js';
import { cn } from '~/utils/classnames';
import styles from './styles.module.css';

type RingLoaderProps = {
  class?: string;
  size?: number;
  color?: string;
  borderWidth?: number;
};

export const RingLoader: Component<RingLoaderProps> = (originProps) => {
  const props = mergeProps(
    {
      color: '#fff',
      size: 20,
      borderWidth: 2,
    },
    originProps
  );

  const childStyle = {
    width: `${props.size}px`,
    height: `${props.size}px`,
    margin: `${props.borderWidth}px`,
    'border-width': `${props.borderWidth}px`,
    'border-color': `${props.color} transparent transparent transparent`,
  };

  return (
    <div
      class={cn(styles['lds-ring'], 'relative', props.class)}
      style={{ width: `${props.size + props.borderWidth}px`, height: `${props.size + props.borderWidth}px` }}>
      <div style={childStyle} />
      <div style={childStyle} />
      <div style={childStyle} />
      <div style={childStyle} />
    </div>
  );
};
